@use '@angular/material' as mat;
@import '@angular/material/theming';
@import 'bootstrap/scss/bootstrap';
@import '../src/app/netze-styles-source/lib/helpers/variables';
@import '../src/app/netze-styles-source/own/helpers/enbw-color.scss';
@import '../src/app/netze-styles-source/own/helpers/enbw-typography.scss';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-typography-config());`
//@include mat.all-component-typographies();
@include mat.core();
@include mat.typography-hierarchy($enbw-typography);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

//@include mat.all-component-typographies($enbw-typography);
//@include mat-core($enbw-typography);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($lighton-theme);

@import '../src/app/netze-styles-source/own/helpers/enbw-mat-overrides.scss';


@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(/assets/icons/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format('woff2');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}


/* roboto-300 - latin-ext_latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local(''),
       url('/assets/roboto-v30-latin-ext_latin/roboto-v30-latin-ext_latin-300.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('/assets/roboto-v30-latin-ext_latin/roboto-v30-latin-ext_latin-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-regular - latin-ext_latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local(''),
       url('/assets/roboto-v30-latin-ext_latin/roboto-v30-latin-ext_latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('/assets/roboto-v30-latin-ext_latin/roboto-v30-latin-ext_latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-500 - latin-ext_latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: local(''),
       url('/assets/roboto-v30-latin-ext_latin/roboto-v30-latin-ext_latin-500.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('/assets/roboto-v30-latin-ext_latin/roboto-v30-latin-ext_latin-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

html,
body {
  height: 100%;
  padding: 0;
  margin: 0;
}
body {
  margin: 0;
  font-family: enbw-regular, Roboto, 'Helvetica Neue', sans-serif;
}

@mixin xl {
  @media (min-width: 1200px) {
      @content;
  }
}


.content-container {
  margin: 0 50px;
  min-width: 1100px;
  max-width: 1950px;
}


.di-card {
  background: #FFFFFF;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.08);
  border-radius: 3px;
}

.mat-expansion-panel, .mat-expansion-panel-header, .mat-expansion-panel-body {
  background-color: white !important;
}

.mdc-text-field--filled, .mat-mdc-form-field-focus-overlay {
  background-color: transparent !important;
  padding: 0 !important;

  :hover {
    background-color: transparent !important;
  }
}

.mat-mdc-button-touch-target {
  background-color: transparent !important;
  :hover {
    background-color: transparent !important;
  }
}

.mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0;
  background-color: transparent !important;
}

.mdc-checkbox {
  --mdc-checkbox-selected-checkmark-color: #fff;
}

.mat-mdc-form-field {
  mat-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px !important;
  }
}

.mat-mdc-icon-button span {
  height: 40px !important;
  width: 40px !important;
}

.mat-mdc-tab-group .mat-mdc-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
