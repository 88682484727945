@import "../../lib/helpers/variables";

.jumbotron {
  background-color: rgba($tiefenblau, .1);
  padding: 20px 0 1px;
  margin: 0 auto 30px auto;
  border-radius: 0px;

  h1 {
    font-family: enbw-medium;
    font-style: normal;
    font-weight: normal;
    font-size: 34px;
    line-height: 37px;
    color: $tiefenblau;
    margin-bottom: 10px;
  }

  h2 {
    font-family: enbw-regular;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    color: $black;
  }
}

.information {
  font-family: enbw-regular;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 19px;
  margin-bottom: 50px;
}

#breadcrumb {
  margin-bottom: 50px;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;

    li {
      a {
        font-family: enbw-regular;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 19px;
        text-align: center;
        color: $white;
        text-decoration: none;

        display: block;
        float: left;
        background: $tiefenblau;
        padding: 12px 15px 9px 32px;
        position: relative;
        margin: 0 20px 0 0;
        width: 180px;
      }

      a:before {
        content: '';
        border-top: 20px solid transparent;
        border-bottom: 20px solid transparent;
        border-left: 20px solid $white;
        position: absolute;
        left: 0;
        top: 0;
      }

      a:after {
        content: '';
        border-top: 20px solid transparent;
        border-bottom: 20px solid transparent;
        border-left: 20px solid $tiefenblau;
        position: absolute;
        right: -20px;
        top: 0;
      }
    }

    li.incorrect {
      a {
        background-color: $signalrot;
      }

      a:after {
        border-left-color: $signalrot;
      }
    }

    li.correct {
      a {
        background-color: $gruen;
      }

      a:after {
        border-left-color: $gruen;
      }
    }

    li.active {
      a {
        background-color: $dunkelorange;
      }

      a:after {
        border-left-color: $dunkelorange;
      }
    }
  }
}

.container {
  margin: 0 0 0 50px;
  max-width: 1100px;
}

.error-container {
  display: flex;
  margin: 10px 10px 50px 0px;
  justify-content: left;

  span {
    background: $error-bg;
    border: 1px solid $error-bd;
    border-radius: 2px;
    font-family: enbw-regular;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 22px;
    padding: 20px;
    color: $signalrot;
  }
}

.hint-container {
  display: flex;
  margin: 10px 10px 10px 10px;
  justify-content: left;

  span {
    background: $lichtgrau;
    border: 1px solid $grau-70;
    border-radius: 2px;
    font-family: enbw-regular;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 22px;
    padding: 10px;
    color: $black;
  }
}

.algn {
  display: flex;
  justify-content: flex-start;
  align-content: flex-end;
  align-items: center;
  text-align: left;
}

.actbtn {
  border: none;
  color: $grau-70;
  background-color: $white;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0px;
  &:hover {
    color: $horizontorange;
  }
}

// modal windows
.information-mdl {
  font-family: enbw-regular;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 19px;
  margin-bottom: 15px;
  width: 100%;
  text-align: center;
}

.information2-mdl {
  font-family: enbw-regular;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 19px;
  margin-bottom: 0px;
}

.cls-btn {
  color: $black;
  background-color: $white;
  margin: 0px;
  border: none;
  display: flex;
  justify-content: flex-end;
  align-content: center;
  &:focus {
    outline: none;
  }
}

.cls-div {
  display: flex;
  justify-content: flex-end;
  align-content: center;
  align-items: flex-end;
}

.div-stl {
  align-items: center;
  justify-content: center;
  width: 100%;
  display: flex;
}

.h1-mdl {
  font-family: enbw-regular;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  color: $tiefenblau;
  width: 100%;
  margin-top: 15px;
}

.h2-mdl {
  font-family: enbw-regular;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  text-align: left;
  color: $black;
  margin-bottom: 5px;
  padding-top: 15px;
}

.support-link {
  font-family: enbw-regular;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 23px;
  text-align: center;
  color: $horizontorange;
  text-decoration: none;
  padding: 10px 0;
  &:hover {
    color: $tiefenblau;
  }
}

.cnt {
  text-align: right;
  color: $grau-50;
  font-family: enbw-regular;
  width: 505px;
}

.page-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
  & h1 {
    margin-bottom: 0;
    color: $tiefenblau;
  }

  & h4 {
    margin-bottom: 0;
  }
}
