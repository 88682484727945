@import "../../../../../node_modules/bootstrap/scss/mixins";
@import "../../../../../node_modules/bootstrap/scss/functions";
@import "../../../../../node_modules/bootstrap/scss/variables";

///////////////////////////////////////////////////////////////////////////////////////////////////
// Netze BW Custom Variables and Overrides
// This file is based on bootstraps variables and overrides it when needed
///////////////////////////////////////////////////////////////////////////////////////////////////

// Colors & Box-Shadow
// --------------------

$tiefenblau: #000099;
$horizontorange: #ff9900;
$anthrazitgrau: #3b3b3b;
$lichtgrau: #e3e3e3;
$grau-70: #686868;
$grau-60: #838383;
$grau-50: #979797;
$grau-30: #cccccc;
$grau-20: #dfdfdf;
$grau-10: #eeeeee;

$white: #ffffff;
$black: #000000;

$body-bg: $white;
$grau-disabled: #f9f9f9;
$grau-header: #f8f8f8;
$nachtblau: #061671;
$mittelblau: #374a9a;
$hellblau: #8aaddc;
$spartenblau: #5eb6e3;
$signalrot: #e2001a;
$signalgruen: #94c11c;
$gruen: #49c114;
$dunkelorange: #ee7700;
$hellorange: #fff0d9;
$warngelb: #fff68b;
$fehlerrot: #b61717;
$contact-modal: #efefef;
$modal-backdrop: #efefef;
$blue: $tiefenblau;
$green: $signalgruen;
$boxshadow-default: 0 5px 40px rgba(0, 0, 0, 0.25);
// cgi own definitions
$error-bg: #f8d7da;
$error-bd: #f5c6cb;
$durchsichtigblau: #e5e5f5;

// Variables for compilation
// Needed for our overrides, DO NOT CHANGE or DELETE
// --------------------

$line-height-base: 1.5;
$input-btn-line-height: $line-height-base;
$input-btn-padding-y: 0.375rem !default;
$input-btn-padding-x: 0.75rem !default;
$input-btn-padding-y: 0.375rem;
$border-width: 1px;
$border-radius: 0.25rem;
$input-btn-border-width: $border-width;
$input-border-width: $input-btn-border-width;
$input-height-border: $input-border-width * 2;
$line-height-sm: 1.5;
$input-btn-focus-width: 0.2rem;
$input-btn-padding-y-lg: 0.5rem;
$line-height-lg: 1.5;
$input-btn-line-height-sm: $line-height-sm;
$input-btn-padding-y-sm: 0.25rem;
$input-padding-y-sm: $input-btn-padding-y-sm;
$input-btn-line-height-lg: $line-height-lg;
$input-btn-focus-box-shadow: 0 0 0 0.2rem rgba($tiefenblau, 0.25);

// ND-Specials
// --------------------

$nd-form-element-height: 4rem;
$nd-form-element-inner-height: 3.8rem;

// Fonts & Typography
// --------------------

$font-family-sans-serif: 'EnBWDINWebPro', -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, sans-serif;
$font-family-icon: 'NetzeBW-ICO' !important;

// Tables
// --------------------
$table-bg: transparent;
$table-dark-color: $body-bg;

// Forms
// --------------------
$cursor-disabled: not-allowed;
$input-height-inner: ($font-size-base * $input-btn-line-height) + ($input-btn-padding-y * 2);
$input-height: calc(#{$input-height-inner} + #{$input-height-border});
$input-height-inner-sm: ($font-size-sm * $input-btn-line-height-sm) + ($input-btn-padding-y-sm * 2);
$input-height-sm: calc(#{$input-height-inner-sm} + #{$input-height-border});
$input-height-inner-lg: ($font-size-lg * $input-btn-line-height-lg) + ($input-btn-padding-y-lg * 2);
$input-height-lg: calc(#{$input-height-inner-lg} + #{$input-height-border});
$custom-control-indicator-box-shadow: inset 0 0.25rem 0.25rem rgba($black, 0.1);
$custom-control-indicator-disabled-bg: $grau-20;
$custom-control-indicator-focus-box-shadow: 0 0 0 1px $body-bg, $input-btn-focus-box-shadow;
$custom-select-padding-y: 0.375rem;
$custom-select-padding-x: 0.75rem;
$custom-select-line-height: $input-btn-line-height;
$custom-select-focus-width: $input-btn-focus-width;
$custom-select-focus-box-shadow: 0 0 0 $custom-select-focus-width rgba(lighten($tiefenblau, 25%), 0.5);
$custom-select-font-size-sm: 75%;
$custom-select-font-size-lg: 125%;
$custom-range-thumb-focus-box-shadow: 0 0 0 1px $body-bg, $input-btn-focus-box-shadow;
$custom-file-focus-box-shadow: $input-btn-focus-box-shadow;
$custom-file-padding-y: $input-btn-padding-y;
$custom-file-padding-x: $input-btn-padding-x;
$custom-file-line-height: $input-btn-line-height;
$custom-file-border-width: $input-btn-border-width;

// Dropdowns
// --------------------

$dropdown-min-width: 10rem;
$dropdown-padding-y: 0.5rem;
$dropdown-spacer: 0.125rem;
$dropdown-bg: $white;
$dropdown-border-color: rgba($black, 0.15);
$dropdown-border-radius: $border-radius;
$dropdown-border-width: $border-width;
$dropdown-divider-bg: $grau-20;
$dropdown-box-shadow: 0 0.5rem 1rem rgba($black, 0.175);
$dropdown-link-active-color: $white;
$dropdown-link-active-bg: $tiefenblau;
$dropdown-link-disabled-color: $grau-60;
$dropdown-item-padding-y: 0.25rem;
$dropdown-item-padding-x: 1.5rem;
$dropdown-header-color: $grau-60;
