/* For use in src/lib/core/theming/_palette.scss */
$md-warn: (
    50 : #f6e3e3,
    100 : #e9b9b9,
    200 : #db8b8b,
    300 : #cc5d5d,
    400 : #c13a3a,
    500 : #b61717,
    600 : #af1414,
    700 : #a61111,
    800 : #9e0d0d,
    900 : #8e0707,
    A100 : #ffbcbc,
    A200 : #ff8989,
    A400 : #ff5656,
    A700 : #ff3c3c,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #ffffff,
    )
);

