@import "../../lib/helpers/variables";
@import "../../../../../node_modules/bootstrap/scss/mixins";

// Button(s)
///////////////////////////////////////////////////////////////////////////////////////////////////

.nd-btn {
    // Block
    // -----------------------------
    position: relative !important;
    padding: 0 1.5rem 0 1.5rem !important;
    display: inline-block !important;
    border: 0 !important;
    border-radius: 0.3rem !important;
    outline: none;
    font-weight: normal;
    font-size: $font-size-base;
    line-height: 1.9rem !important;
    height: 3rem !important;
    text-align: center !important;
    cursor: pointer;
    color: $dunkelorange !important;
    background-color: $white !important;
    white-space: nowrap;

    // Avoid growing out of boxes
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;

    // Elements & Children
    // -----------------------------
    span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    // Animated Icons -> On right side
    .fa-angle-right,
    .nd-ico-arrow-right,
    .fa-angle-down,
    .nd-ico-arrow-down,
    .nd-ico-user-settings {
        transition: transform 0.2s;
        margin-left: 0.2rem;
        font-size: 1.4rem;
    }

    .fa-angle-left,
    .nd-ico-arrow-left,
    .nd-ico-add,
    .nd-ico-user-settings {
        margin-right: 0.4rem;
    }

    .nd-loader {
        position: absolute;
        top: 0;
        right: -4rem;
        transition: right 0.3s;
        opacity: 0;
        .nd-spinner {
            margin-right: 0;
            margin-top: 0.8rem;
        }
    }

    // States
    // -----------------------------

    &:focus {
        outline: none;
    }

    &:hover {
        color: $dunkelorange;
        .fa-angle-right,
        .nd-ico-arrow-right {
            transform: translateX(0.4rem);
        }
    }

    &.disabled,
    &:disabled {
        opacity: 0.5;
        pointer-events: none;
    }

    .active & {
        .fa-angle-down,
        .nd-ico-arrow-down {
            transform: rotate(-180deg);
        }
    }
    &.pressed {
        background: $grau-30;
        color: $white;
    }

    // Variants & Modifiers
    // -----------------------------

    &.icon {
        .fa {
            margin-left: 0.45rem;
        }
    }
    &.bg-white {
        background: $white;
    }
    &.no-padding {
        padding: 0;
    }

    &--loader {
        position: relative;
        overflow: hidden;
        transition: padding-right 0.3s;
        .nd-ico-check {
            display: none;
            margin-left: 0.5rem;
        }
    }

    &--loading {
        padding-right: 4rem;
        .nd-loader {
            right: 1rem;
            opacity: 1;
        }
    }

    &--noaction {
        pointer-events: none;
    }

    &--nopadding {
        padding-left: 0;
        padding-right: 0;
    }

    &--hasicon {
        line-height: $nd-form-element-height;
        padding: 0 1.2rem 0 1.5rem;
    }

    &--wide {
        padding: 0 1.5rem;

        @include media-breakpoint-up(md) {
            padding: 0 6rem;
        }
    }

    &--download {
        display: flex;
        align-items: center;
        text-align: left;
        padding-left: 2.25rem;
        position: relative;
        line-height: 2.25rem;
        text-decoration: none !important;
        background-color: transparent;

        &:hover {
            & span {
                text-decoration: underline;
            }
        }

        i {
            position: absolute;
            left: 0;
            line-height: 2.25rem;
        }

        & span.file-info {
            color: $grau-70;
            font-size: 1.35rem;
            margin-left: 1rem;
            text-decoration: none !important;
        }
    }

    &--margin {
        margin: 0;

        @include media-breakpoint-up(md) {
            margin-left: 2.5rem;
            margin-right: 2.5rem;
        }
    }

    &--transparent {
        background: transparent !important;
    }

    &--saveprogress {
        transition: box-shadow 0.2s;

        &:hover {
            box-shadow: 0 5px 20px rgba(0, 0, 0, 0.25);
        }

        i {
            display: inline-block;
            margin-right: 1rem;
        }
    }

    &--navigation {
        position: absolute;
        top: 2.8rem;
        right: 0.1rem;
        height: 3.8rem;
        padding: 0 1rem 0 1rem;

        i {
            color: $anthrazitgrau;
        }

        &:hover {
            i {
                color: $dunkelorange;
            }
        }
    }

    // Types
    // -----------------------------

    &-main {
        background-color: $horizontorange !important;
        background-image: linear-gradient(to bottom, $horizontorange, $dunkelorange) !important;

        color: $white !important;

        &:hover {
            background: $horizontorange;
            color: $white;
            & span {
                text-decoration: underline;
            }
        }
        &:active {
            background: linear-gradient(to bottom, $dunkelorange, $horizontorange);
        }
    }

    &-maingrey {
        background: $grau-header;
        color: $grau-70;
        border: 1px solid $grau-30;

        &:hover {
            color: $grau-70;
            text-decoration: underline;
        }
    }

    &-light {
        background: $white;
        &:hover {
            color: $dunkelorange;
            text-decoration: underline;
        }
    }

    &-lightgrey {
        color: $grau-50;
        background: $white;
        &:hover {
            color: $grau-50;
            text-decoration: underline;
        }
    }

    &-darkgrey {
        color: $grau-70;
        background: $white;
        &:hover {
            color: $grau-70;
            text-decoration: underline;
        }
    }

    &-outline {
        background: $white;
        border: 1px solid $dunkelorange;
        color: $dunkelorange !important;
        line-height: 3.6rem;
        &:hover,
        &:active {
            border-color: $dunkelorange;
            color: $dunkelorange;
            span {
                text-decoration: underline;
            }
        }
        &.nd-btn--hasicon {
            line-height: $nd-form-element-inner-height;
        }
    }

    &-outlinegrey {
        background: $white;
        border: 1px solid $grau-50;
        color: $grau-70;
        line-height: 3.6rem;
        &:hover,
        &:active {
            color: $dunkelorange;
            border-color: $dunkelorange;
            span {
                text-decoration: underline;
            }
        }
        &.nd-btn--hasicon {
            line-height: $nd-form-element-inner-height;
        }
    }
    &-outlinedarkgrey {
        background: $white;
        border: 1px solid $grau-70;
        color: $grau-70;
        line-height: 3.6rem;
        &:hover,
        &:active {
            color: $anthrazitgrau;
            span {
                text-decoration: underline;
            }
        }
        &.nd-btn--hasicon {
            line-height: $nd-form-element-inner-height;
        }
    }

    &-outlinewhite {
        background: transparent;
        border: 1px solid $white;
        color: $white;
        line-height: 3.6rem;
        &:hover,
        &:active {
            color: $white;
            span {
                text-decoration: underline;
            }
        }
        &.nd-btn--hasicon {
            line-height: $nd-form-element-inner-height;
        }
    }

    &-outlinegreen {
        background: transparent;
        border: 1px solid $gruen;
        color: $gruen;
        line-height: 3.6rem;
        pointer-events: none !important;
        .nd-ico-check {
            display: inline;
        }
    }

    &-transparent {
        background: transparent;
        color: $dunkelorange;

        &:hover {
            color: $dunkelorange;
            text-decoration: underline;
        }
    }

    &-search {
        color: $anthrazitgrau;
        padding-right: 0;
        padding-left: 1.2rem;
        background: transparent;

        &:hover {
            color: $horizontorange;
        }
    }

    &-white {
        color: $dunkelorange;
        background-color: $white;
        &:hover {
            color: $dunkelorange;
        }
    }

    &-black {
        color: $anthrazitgrau;
        background: transparent;
    }

    &-datepickerpreset {
        border-radius: 0;

        &:hover {
            text-decoration: underline;
        }
    }

    &-language {
        position: absolute;
        z-index: 100;
        top: 0;
        right: 1rem;
        background: transparent;
        padding-left: 1rem;
        padding-right: 1rem;
        font-size: 1.4rem;
        line-height: 0;
        &__ico {
            width: 1.8rem;
            height: 1.2rem;
        }
    }

    &-pager {
        font-size: 1.5rem;
        line-height: 2.8rem;
        height: 3rem;
        background: $white;
        border: 1px solid $dunkelorange;
        color: $dunkelorange;
        padding: 0 0.8rem;
        margin: 0 0.3rem;
        &:hover,
        &:active {
                border-color: $dunkelorange;
                color: $dunkelorange;
                transform: none;
                .nd-ico-arrow-right {
                transform: none;
            }
        }
        & i {
            font-size: 1.5rem !important;
            margin-left: 0 !important;
            margin-right: 0 !important;
        }

        &.nd-btn-inactive {
            opacity: 0.6;
        }
    }
}

///////////////////////////////////////////////////////////////////////////////////////////////////
// Actionbutton
///////////////////////////////////////////////////////////////////////////////////////////////////

.nd-actionbtn {
    // Block
    // -----------------------------
    position: relative;
    background: $white;
    border: 1px solid $dunkelorange;
    line-height: $nd-form-element-inner-height;
    transition: box-shadow 0.2s;

    // States
    // -----------------------------

    &:hover {
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
    }

    &:active {
        box-shadow: none;
    }

    &.disabled,
    &:disabled {
        opacity: 0.5;
        pointer-events: none;
    }

    // Elements & Children
    // -----------------------------

    i {
        margin-right: 1rem;
    }

    &__loader {
        position: absolute;
        opacity: 0;
        top: 0;
        left: 0;
        width: 38px;
        height: 38px;
        background: #fff;
        text-align: center;
        transition: opacity 0.2s;

        .nd-spinner {
            margin-left: 8px;
            margin-top: 8px;
        }
    }

    // Modifier
    // -----------------------------

    &--loading {
        .nd-actionbtn__loader {
            display: block;
            opacity: 1;
        }

        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);

        span {
            display: inline;
        }

        i {
            margin-right: 1rem;
        }
    }
}

///////////////////////////////////////////////////////////////////////////////////////////////////
// Pulsating Button
///////////////////////////////////////////////////////////////////////////////////////////////////

.nd-btn-pulse {
    // Block
    // -----------------------------
    position: relative;
    width: 3.2rem;
    height: 3.2rem;
    line-height: 3rem;
    border: none;
    outline: none;
    border-radius: 50%;
    background-color: $dunkelorange;
    box-shadow: 0 0 0 0 rgba(238, 119, 0, 0.7);
    cursor: pointer;
    animation: ndBtnPulse 4s infinite cubic-bezier(0.66, 0, 0, 1);

    // States
    // -----------------------------
    &:hover {
        animation: none;
    }

    &:focus {
        outline: none;
    }

    & i {
        color: $white;
        font-size: 1.4rem;
    }
}

@keyframes ndBtnPulse {
    50% {
        box-shadow: 0 0 0 20px rgba(238, 119, 0, 0);
    }

    100% {
        box-shadow: 0 0 0 20px rgba(238, 119, 0, 0);
    }
}

.nd-zseBtnMobile {
    .nd-btn-main {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        height: 3rem;
        line-height: 3rem;
        font-size: 1.5rem;
    }
}

// own stuff
.btn-fnt {
  font-family: enbw-regular;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  height: 44px;
  width: 200px;
  text-align: center;
  align-content: center;
  justify-content: center;
}

.btn-spc {
    margin-right: 16px;
    margin-left: 16px;
    padding: 15px;
}

.btn-spc-leg {
    margin-right: 33px;
    padding: 15px;
}
