/* For use in src/lib/core/theming/_palette.scss */
$md-primary: (
    50 : #e0e0f3,
    100 : #b3b3e0,
    200 : #8080cc,
    300 : #4d4db8,
    400 : #2626a8,
    500 : #000099,
    600 : #000091,
    700 : #000086,
    800 : #00007c,
    900 : #00006b,
    A100 : #9a9aff,
    A200 : #6767ff,
    A400 : #3434ff,
    A700 : #1a1aff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #ffffff,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

