@use '@angular/material' as mat;
@import '@angular/material/theming';

@font-face {
  font-family: 'enbw-light';
  src: url('/assets/EnBWDINPro/EnBWDINPro-Light.ttf');
}
@font-face {
  font-family: 'enbw-bold';
  src: url('/assets/EnBWDINPro/EnBWDINPro-Bold.ttf');
}
@font-face {
  font-family: 'enbw-medium';
  src: url('/assets/EnBWDINPro/EnBWDINPro-Medium.ttf');
}
@font-face {
  font-family: 'enbw-regular';
  src: url('/assets/EnBWDINPro/EnBWDINPro-Regular.ttf');
}

@font-face {
  font-family: 'NetzeBW-ICO';
  src: url('/assets/NetzeBW-ICO/NetzeBW-ICO.ttf');
}

// Define a custom typography config that overrides the font-family as well as the
// `headlines` and `body-1` levels.
$enbw-typography: mat.define-typography-config(
  $font-family: 'enbw-regular, Roboto, sans-serif'
);
