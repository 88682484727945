///////////////////////////////////////////////////////////////////////////////////////////////////
// Provide classes for all our icons out of the custom icon font
///////////////////////////////////////////////////////////////////////////////////////////////////

[class^='nd-ico-'],
[class*='nd-ico-'] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'NetzeBW-ICO' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    display: inline-block;
}

.nd-ico-add-archive:before {
    content: '\e937';
}
.nd-ico-add-company:before {
    content: '\e938';
}
.nd-ico-add-plumber:before {
    content: '\e939';
}
.nd-ico-create-letter:before {
    content: '\e93a';
}
.nd-ico-edit:before {
    content: '\e93b';
}
.nd-ico-options:before {
    content: '\e93c';
}
.nd-ico-add:before {
    content: '\e900';
}
.nd-ico-arrow-down:before {
    content: '\e901';
}
.nd-ico-arrow-left-large:before {
    content: '\e902';
}
.nd-ico-arrow-left:before {
    content: '\e903';
}
.nd-ico-arrow-right-large:before {
    content: '\e904';
}
.nd-ico-arrow-right:before {
    content: '\e905';
}
.nd-ico-arrow-up:before {
    content: '\e906';
}
.nd-ico-bars:before {
    content: '\e907';
}
.nd-ico-biomass:before {
    content: '\e908';
}
.nd-ico-bullet-point:before {
    content: '\e909';
}
.nd-ico-calendar:before {
    content: '\e90a';
}
.nd-ico-chat:before {
    content: '\e90b';
}
.nd-ico-check-circle-mini:before {
    content: '\e90c';
}
.nd-ico-check-circle:before {
    content: '\e90d';
}
.nd-ico-check:before {
    content: '\e90e';
}
.nd-ico-close:before {
    content: '\e90f';
}
.nd-ico-compass:before {
    content: '\e910';
}
.nd-ico-delete:before {
    content: '\e911';
}
.nd-ico-disabled:before {
    content: '\e912';
}
.nd-ico-download:before {
    content: '\e913';
}
.nd-ico-electricity:before {
    content: '\e914';
}
.nd-ico-envelope:before {
    content: '\e915';
}
.nd-ico-facebook:before {
    content: '\e916';
}
.nd-ico-finger:before {
    content: '\e917';
}
.nd-ico-gas:before {
    content: '\e918';
}
.nd-ico-geothermal:before {
    content: '\e919';
}
.nd-ico-googleplus:before {
    content: '\e91a';
}
.nd-ico-home:before {
    content: '\e91b';
}
.nd-ico-info:before {
    content: '\e91c';
}
.nd-ico-inspection:before {
    content: '\e91d';
}
.nd-ico-instagram:before {
    content: '\e91e';
}
.nd-ico-kwk:before {
    content: '\e91f';
}
.nd-ico-linkedin:before {
    content: '\e920';
}
.nd-ico-list:before {
    content: '\e921';
}
.nd-ico-location:before {
    content: '\e922';
}
.nd-ico-minus-circle:before {
    content: '\e923';
}
.nd-ico-minus:before {
    content: '\e924';
}
.nd-ico-more:before {
    content: '\e925';
}
.nd-ico-notifications:before {
    content: '\e926';
}
.nd-ico-phone:before {
    content: '\e927';
}
.nd-ico-plus-circle:before {
    content: '\e928';
}
.nd-ico-reload:before {
    content: '\e929';
}
.nd-ico-resize:before {
    content: '\e92a';
}
.nd-ico-search:before {
    content: '\e92b';
}
.nd-ico-share:before {
    content: '\e92c';
}
.nd-ico-sun:before {
    content: '\e92d';
}
.nd-ico-thumb-down:before {
    content: '\e92e';
}
.nd-ico-thumb-up:before {
    content: '\e92f';
}
.nd-ico-twitter:before {
    content: '\e930';
}
.nd-ico-upload:before {
    content: '\e931';
}
.nd-ico-user:before {
    content: '\e932';
}
.nd-ico-water:before {
    content: '\e933';
}
.nd-ico-wind:before {
    content: '\e934';
}
.nd-ico-xing:before {
    content: '\e935';
}
.nd-ico-youtube:before {
    content: '\e936';
}
.nd-ico-copy:before {
    content: '\e93d';
}
.nd-ico-lightbulb:before {
    content: '\e93e';
}
.nd-ico-fuse:before {
    content: '\e93f';
}
.nd-ico-internet:before {
    content: '\e940';
}
.nd-ico-user-settings:before {
    content: '\e941';
}
.nd-ico-lock-open:before {
    content: '\e942';
}
.nd-ico-solar:before {
    content: '\e945';
}
.nd-ico-installations:before {
    content: '\e944';
}
.nd-ico-counter:before {
    content: '\e946';
}
.nd-ico-monitor:before {
    content: '\e943';
}
.nd-ico-overview:before {
    content: '\e947';
}
.nd-ico-logout:before {
    content: '\e948';
}
.nd-ico-grid:before {
    content: '\e949';
}
.nd-ico-caret-right:before {
    content: '\e94a';
}
.nd-ico-caret-left:before {
    content: '\e94b';
}
.nd-ico-caret-up:before {
    content: '\e94c';
}
.nd-ico-caret-down:before {
    content: '\e94d';
}
.nd-ico-cloud:before {
    content: '\e94e';
}
.nd-ico-add-counter:before {
    content: '\e94f';
}
.nd-ico-add-esp:before {
    content: '\e950';
}
.nd-ico-add-ims:before {
    content: '\e951';
}
.nd-ico-books:before {
    content: '\e952';
}
.nd-ico-camera:before {
    content: '\e953';
}
.nd-ico-clock:before {
    content: '\e954';
}
.nd-ico-hourglass:before {
    content: '\e955';
}
.nd-ico-map:before {
    content: '\e956';
}
.nd-ico-todo:before {
    content: '\e957';
}
.nd-ico-document:before {
    content: '\e958';
}
