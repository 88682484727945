@import '../../lib/helpers/variables';

// radio button
/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
.mat-mdc-radio-button.mat-accent .mat-radio-inner-circle {
  color: $horizontorange;
  background-color: $horizontorange;
}

/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
.mat-mdc-radio-button.mat-accent .mat-radio-outer-circle {
  border-color: $horizontorange;
}


/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
.mat-mdc-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
  background-color: $tiefenblau;
}

// checkbox
.mdc-checkbox__background {
  border-color: $horizontorange !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  background-color: $horizontorange;
}

// pseudo checkbox
.mat-pseudo-checkbox {
  color: $horizontorange;
}

.mat-pseudo-checkbox-checked {
  background: $horizontorange;
}

// grid tile
.mat-grid-tile .mat-figure {
  justify-content: flex-start !important;
  align-items: flex-start !important;
}

// paginator
.mat-mdc-paginator {
  display: flex;
  justify-content: flex-end;
  font-style: normal;
}

// expansion panel
.mat-expansion-panel {
  background-color: rgba($lichtgrau, .3);
}

.mat-expansion-panel-header {
  background-color: rgba($lichtgrau, .3);
}

.mat-expansion-panel-body {
  background-color: rgba($lichtgrau, .3);
}

// header sort arrow
.mat-sort-header-arrow {
  color: $horizontorange;
}

.mat-sort-header-container:not(.mat-sort-header-sorted)
  .mat-sort-header-arrow {
  opacity: 1;
}

// tab group
.mat-mdc-tab-group {
  height: auto;
  width: 1100px;
}

// tab label
/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
.mat-tab-label {
  font-style: normal;
  font-size: 16px;
  color: $black;
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
.mat-tab-label .mat-tab-label-content {
  font-size: 16px;
}

// buttons
.mat-mdc-raised-button.mat-accent {
  color: white !important;
  text-decoration: none;
}
